import React, { useEffect } from "react";
import { OneTapLoginConstants, checkCartAndLoginCookie } from "./common";
import { getGoogleResponseCall } from "../actions/login-actions";

/**
 * This functional component provides the functionality of Google One Tap Login
 *
 * @returns {React.ReactElement} It returns nothing.
 */
const OneTapLogin = () => {
  /**
   * @function handleCredentialResponse this is the callback function from the initialisation
   * @param {*} res credential response in form of JWT
   */
  const handleCredentialResponse = async (res) => {
    if (res.credential) {
      window.sessionStorage.setItem("ctapSocialLogin", true);

      await getGoogleResponseCall(res.credential);

      window.location.reload();
    }
  };

  useEffect(() => {
    if (!checkCartAndLoginCookie()) {
      // Initialize Google One Tap
      if (window.google && window.google.accounts && window.google.accounts.id) {
        window.google.accounts.id.initialize({
          client_id: OneTapLoginConstants.CLIENT_ID,
          callback: handleCredentialResponse,
          cancel_on_tap_outside: false,
        });
        window.google.accounts.id.prompt();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default OneTapLogin;
